import React from "react";
import TitledList from "./titledList";
import { keywords } from "../assets/constants";
export default function KeywordFooter() {
    return (
      <div
        className='w-100 color-bkgr-hazel flex flex-evenly padding-2vw-5vw'
      >
        <TitledList
          title="Miasta"
          links={ keywords[0].map(keyword => {
              return {
                name: keyword,
                url: keyword
              };
          }) }
        />
        <TitledList
          title="Gatunki"
          links={ keywords[1].map(keyword => {
              return {
                name: keyword,
                url: keyword
              };
          }) }
        />
        <TitledList
          title="Biznes"
          links={ keywords[2].map(keyword => {
              return {
                name: keyword,
                url: keyword
              };
          }) }
        />

      </div>
    );
}
