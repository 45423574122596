const EventTypes ={
  FIREBASE_INIT: "FIREBASE_INIT",
  SET_NAVBAR:"SET_NAVBAR",
  SET_USER: "SET_USER",
  OPEN_MODAL:"OPEN_MODAL",
  OPEN_ACCOUNT:"OPEN_ACCOUNT",
  SET_SEARCH_RESULTS:"SET_SEARCH_RESULTS",
  ALBUM_TO_BOOK: "ALBUM_TO_BOOK",
  BOOKING_DETAILS: "BOOKING_DETAILS",
  BOOKING_DATE:"BOOKING_DATE",
  IS_MOBILE: "TRUE",
  UPDATE_CARDS: "UPDATE_CARDS",
  UPDATE_GOOGLE_API: "UPDATE_GOOGLE_API",
  SELECT_LANGUAGE: "SELECT_LANGUAGE",
  LOADING: "LOADING",
  LOADING_DOCS:"LOADING_DOCS",
  FREE_CANCEL:"FREE_CANCEL",
  NEW_NOTIFICATION:"NEW_NOTIFICATION",
  REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
  FORCE_REMOVE_NOTIFICATION: "FORCE_REMOVE_NOTIFICATION",
  UPDATE_PHOTOGRAPHER: "UPDATE_PHOTOGRAPHER",
  UPDATE_BOOKINGS: "UPDATE_BOOKINGS",
  EMAIL_TO_SEND: "EMAIL_TO_SEND",
  CANCEL_BOOKING:"CANCEL_BOOKING",
  UPDATE_MY_ALBUMS:"UPDATE_MY_ALBUMS",
  EDIT_ALBUM:"EDIT_ALBUM",
  ARE_THERE_MORE_DOCS:"ARE_THERE_MORE_DOCS",
  MAIN_SCROLL: "MAIN_SCROLL",
  DOWNLOAD_MORE: "DOWNLOAD_MORE",
  CHANGE_ADDALBUM_STATE:"CHANGE_ADDALBUM_STATE",
  ALBUM_DATA_SEARCHED: "ALBUM_DATA_SEARCHED",
  BOOKING_DURATION: "BOOKING_DURATION",
  UPDATE_REVIEW_DATA: "UPDATE_REVIEW_DATA"
};

export default EventTypes;