/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import { createContext, useReducer } from "react";
import moment from "moment";
import english from "../assets/locales/web-en.json";
import polish from "../assets/locales/web-pl.json";
import ukrainian from "../assets/locales/web-uk.json";
import errorEnglish from "../assets/locales/error.English.json";
import errorPolish from "../assets/locales/error.Polish.json";
import errorUkrainian from "../assets/locales/error.Ukrainian.json";

import EventTypes from "./eventTypes";
const initialState = {
  firebase: null,
  navbar: "default",
  googleProvider: null,
  facebookProvider: null,
  user: null,
  openModal: false,
  isMobile: false,
  cards: [],
  modal: null,
  modalState: null,
  openAccount: false,
  searchDocs: [],
  bookingDetails: null,
  bookingDate: moment().add(2, "weeks"),
  bookingDuration: 1,
  isFreeCancel: true,
  mainSearchForm: {
    city: null,
    startDate: null,
    endDate: null
  },
  db: null,
  storage: null,
  auth: null,
  analytics: null,
  links: ["faq", "help", "about", "provide your services", "login"],
  albumToBook: null,
  currencies: ["usd", "pln"],
  albumDataSearched: {
    place_id: null,
    genres: null
  },
  genres: [{ label: "Portrait",
    value: "portrait" }, { value: "family",
    label: "Family" }],
  googleApi: null,
  locales: {
    en: english,
    pl: polish,
    uk: ukrainian
  },
  errorLocales: {
    en: errorEnglish,
    pl: errorPolish,
    uk: errorUkrainian
  },
  notificationList: [],
  selectedLanguage: "pl",
  isLoading: true,
  cache: {},
  photographer: null,
  emailSubject: null,
  activeBookings: [],
  orders: [],
  canceledBookings: [],
  upcomingOrders: [],
  completedOrders: [],
  emailToSend: null,
  cancelBooking: null,
  myAlbums: [],
  editAlbum: null,
  areThereMoreDocs: false,
  mainScroll: false,
  isLoadingDocs: false,
  downloadMore: false,
  reviewData: {},
  addAlbumState: {

  }
};

const store = createContext(initialState);
const { Provider } = store;

const Stateprovider = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case EventTypes.FIREBASE_INIT:
              state = {
                ...state,
                firebase: action.payload.firebase,
                googleProvider: action.payload.googleProvider,
                facebookProvider: action.payload.facebookProvider,
                storage: action.payload.storage,
                db: action.payload.db,
                auth: action.payload.auth,
                analytics: action.payload.analytics
              };
              return state;
            case EventTypes.SET_USER:
              state = { ...state,
                user: action.payload };
              return state;
            case EventTypes.SET_NAVBAR:
              state = { ...state,
                navbar: action.payload };
              return state;
            case EventTypes.EDIT_ALBUM:
              state = { ...state,
                editAlbum: action.payload };
              return state;
            case EventTypes.MAIN_SCROLL:
              state = { ...state,
                mainScroll: action.payload };
              return state;
            case EventTypes.OPEN_MODAL:
              return { ...state,
                openModal: action.payload.open,
                modal: action.payload.modal,
                modalState: action.payload.modalState };
            case EventTypes.OPEN_ACCOUNT:
              return { ...state,
                openAccount: true };
            case EventTypes.SET_SEARCH_RESULTS:
              return { ...state,
                searchDocs: action.payload };
            case EventTypes.CHANGE_ADDALBUM_STATE:
              return { ...state,
                addAlbumState: action.payload };
            case EventTypes.ALBUM_TO_BOOK:
              return { ...state,
                albumToBook: action.payload };
            case EventTypes.BOOKING_DETAILS:
              return { ...state,
                bookingDetails: action.payload };
            case EventTypes.BOOKING_DATE:
              return { ...state,
                bookingDate: action.payload };
            case EventTypes.BOOKING_DURATION:
              return { ...state,
                bookingDuration: action.payload };
            case EventTypes.IS_MOBILE:
              return { ...state,
                isMobile: action.payload };
            case EventTypes.UPDATE_CARDS:
              return { ...state,
                cards: action.payload };
            case EventTypes.UPDATE_GOOGLE_API:
              return { ...state,
                googleApi: action.payload };
            case EventTypes.SELECT_LANGUAGE:
              localStorage.setItem("mmnt-lang", action.payload);
              return { ...state,
                selectedLanguage: action.payload };
            case EventTypes.LOADING:
              return { ...state,
                isLoading: action.payload };
            case EventTypes.DOWNLOAD_MORE:
              return { ...state,
                downloadMore: action.payload };
            case EventTypes.LOADING_DOCS:
              return { ...state,
                isLoadingDocs: action.payload };
            case EventTypes.FREE_CANCEL:
              return { ...state,
                isFreeCancel: action.payload };
            case EventTypes.NEW_NOTIFICATION:
              const newNotificationList = [...state.notificationList, action.payload];
              return { ...state,
                notificationList: newNotificationList };
            case EventTypes.REMOVE_NOTIFICATION:
              const newArray = state.notificationList;
              newArray.shift();
              return { ...state,
                notificationList: newArray };
            case EventTypes.FORCE_REMOVE_NOTIFICATION:
              return { ...state,
                notificationList: action.payload };
            case EventTypes.UPDATE_REVIEW_DATA:
              return { ...state,
                reviewData: action.payload };
            case EventTypes.UPDATE_PHOTOGRAPHER:
              return { ...state,
                photographer: action.payload };
            case EventTypes.EMAIL_TO_SEND:
              return { ...state,
                emailToSend: action.payload.email,
                emailSubject: action.payload.emailSubject };
            case EventTypes.CANCEL_BOOKING:
              return { ...state,
                cancelBooking: action.payload };
            case EventTypes.UPDATE_MY_ALBUMS:
              return { ...state,
                myAlbums: action.payload };
            case EventTypes.ARE_THERE_MORE_DOCS:
              return { ...state,
                areThereMoreDocs: action.payload };
            case EventTypes.ALBUM_DATA_SEARCHED:
              return { ...state,
                albumDataSearched: action.payload };
            case EventTypes.UPDATE_BOOKINGS:
              return {
                ...state,
                activeBookings: action.payload.activeBookings,
                upcomingOrders: action.payload.upcomingOrders,
                completedOrders: action.payload.completedOrders,
                canceledBookings: action.payload.canceledBookings
              };
            default:
              throw new Error();
        }
    }, initialState);
    // eslint-disable-next-line react/react-in-jsx-scope
    return <Provider
      value={ { state,
        dispatch } }>
      {children}
    </Provider>;
};

export { store, Stateprovider };