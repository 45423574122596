/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, lazy, Suspense } from "react";
import Skeleton from "react-loading-skeleton";
import EventTypes from "./store/eventTypes";
import Modal from "./modals/modals";
import { initializeApp } from "firebase/app";
import { store } from "./store/store";
import { firebaseConfig } from "./fireBaseconfig";
import {
  getAuth, onAuthStateChanged, connectAuthEmulator,
  GoogleAuthProvider, FacebookAuthProvider, setPersistence, browserLocalPersistence
} from "firebase/auth";
import { getFirestore, enableIndexedDbPersistence, connectFirestoreEmulator, doc, getDoc } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import KeywordFooter from "./components/keywordFooter";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./assets/styles/reactDates_overRides.scss";
import "./assets/styles/app.scss";
import "react-loading-skeleton/dist/skeleton.css";

const NotificationService = lazy(() => import("./services/Notification.service"));
const Routes = lazy(() => import("./routes/routes"));
const Navbar = lazy(() => import("./components/navbar"));
const Footer = lazy(() => import("./components/footer"));

function App() {
    const { state, dispatch } = useContext(store);
    const { isMobile, firebase, auth, analytics, db } = state;
    const userDevice = navigator.userAgent;

    useEffect(() => {
        const language = localStorage.getItem("mmnt-lang");
        if (language) {
          dispatch({ type: EventTypes.SELECT_LANGUAGE,
            payload: language });
        }
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        // if(location.hostname === "localhost"){
        //   connectFirestoreEmulator(db,"localhost",8080);
        // }
        const auth = getAuth(app);
        // if(location.hostname === "localhost"){
        //   connectAuthEmulator(auth,"http://127.0.0.1:9099");
        // }
        if (!firebase) {
          setPersistence(auth, browserLocalPersistence).then(() => {
          }).catch(() => {
          });
          enableIndexedDbPersistence(db);
          fetchUser(auth, db);

        }
        const storage = getStorage(app);
        const analytics = getAnalytics(app);
        const googleProvider = new GoogleAuthProvider();
        const facebookProvider = new FacebookAuthProvider();

        dispatch({ type: EventTypes.FIREBASE_INIT,
          payload: { firebase: app,
            googleProvider,
            facebookProvider,
            db,
            auth,
            analytics,
            storage } });
        getDevice();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch({ type: EventTypes.LOADING,
          payload: true });
        if (firebase && auth && !state.user) {
          fetchUser(auth, db);
        }
        let device = getDevice();
        if (device || window.innerWidth < 1000) {
          dispatch({ type: EventTypes.IS_MOBILE,
            payload: true });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firebase, auth, state.user]);

    useEffect(() => {
        function resizeListener() {
            window.addEventListener("resize", () => {
                if (window.innerWidth <= 1000) {
                  dispatch({ type: EventTypes.IS_MOBILE,
                    payload: true });
                }
                if (window.innerWidth > 1000) {
                  dispatch({ type: EventTypes.IS_MOBILE,
                    payload: false });
                }
            });
        }
        resizeListener();
        return () => {
            window.removeEventListener("resize", () => {
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchUser(auth, db) {
        onAuthStateChanged(auth, async (user) => {
            if (user && user.uid && !state.user) {
              const existingUser = await getDoc(doc(db, "users", user.uid));
              if (existingUser) {
                const userData = await {
                  _id: user.uid,
                  ...existingUser.data()
                };
                dispatch({ type: EventTypes.SET_USER,
                  payload: userData });
              }
              dispatch({ type: EventTypes.LOADING,
                payload: false });
              return;
            } else {
              dispatch({ type: EventTypes.LOADING,
                payload: false });
            }
        });
    }
    const getDevice = () => {
        if (analytics) {
          if (/windows phone/i.test(userDevice)) {
            logEvent(analytics, "mmnt_web_mobile-windows");
            return "Windows Phone";
          }

          if (/android/i.test(userDevice)) {
            logEvent(analytics, "mmnt_web_mobile-android");
            return "Android";
          }

          if (/iPad|iPhone|iPod/.test(userDevice) && !window.MSStream) {
            logEvent(analytics, "mmnt_web_mobile-ios");
            return "iOS";
          } else {
            return false; 
          }
        }

    };

    return (
      <div className="App">
        <Suspense
          fallback={
            <Skeleton
              count={ 1 }
              className={ "fallback-ukrbanner" }
            /> }
        >

        </Suspense>
        {
          isMobile
            ? <Suspense
                fallback={
                  <Skeleton
                    count={ 1 }
                  /> }
            >
              <NotificationService position={ "top-mobile" } />
            </Suspense>
            : null
        }
        <Suspense
          fallback={
            <Skeleton
              count={ 1 }
              className='fallback-navbar'
            />
          }
        >
          <Navbar />
        </Suspense>
        <Suspense
          fallback={
            <Skeleton
              count={ 1 }
              className='fallback-main'
            /> }
        >
          <Routes />
          <Modal />
        </Suspense>
        <KeywordFooter />
        <Suspense
          fallback={ <div>
            Loading FOOTER
          </div>
          }>
          <Footer />

        </Suspense>

      </div>
    );
}

export default App;
