import camera from "./images/camera.svg";
import creditCard from "./images/credit-card.svg";
import fileText from "./images/file-text.svg";
import settings from "./images/settings.svg";
import volumeDown from "./images/volume-down.svg";
import photographerCamera from "./images/photographer-camera.svg";
import userAccount from "./images/useraccount.svg";
import myAlbums from "./images/grid.svg";
import EventTypes from "../store/eventTypes";

export const userLinks = (locales, selectedLanguage) => {

    return [{
      title: locales[selectedLanguage].Personal_Info,
      subtitle: locales[selectedLanguage].Provide_personal_details,
      image: fileText,
      link: "/account/personal-info",
      alt: "Personal information"
    },
    {
      title: locales[selectedLanguage].user_sessions,
      subtitle: locales[selectedLanguage].Control_your_photo_sessions,
      image: camera,
      link: "/account/sessions",
      alt: "Your sessions or reservations"

    },
    {
      title: locales[selectedLanguage].Preferences,
      subtitle: locales[selectedLanguage].Set_your_default_language_and_other,
      image: settings,
      link: "/account/preferences",
      alt: "Preferences"

    },
    // {
    //   title: locales[selectedLanguage].Payment_Details,
    //   subtitle: locales[selectedLanguage].Review_payments_and_payouts,
    //   image: creditCard,
    //   link: "/account/payment-details",
    //   alt: "Payment Details"

    // },
    {
      title: locales[selectedLanguage].Notifications,
      subtitle: locales[selectedLanguage].Choose_notifications_preferences,
      image: volumeDown,
      link: "/account/notifications",
      alt: "Notifications"

    },
    {
      title: locales[selectedLanguage].account_switch_photographer,
      subtitle: null,
      image: photographerCamera,
      link: "",
      alt:"Switch to the Photographer account"
    },];
};

export const userOptions = async (locales, selectedLanguage, dispatch, record, getUserEmailCB) => {
    return [
      {
        title:  locales[selectedLanguage].contact_photographer,
        callBack: async () => {
            const email = await getUserEmailCB();
            dispatch({
              type: EventTypes.EMAIL_TO_SEND,
              payload: {
                email: email,
                emailSubject: `${EMAIL_SUBJECTS.CONTACT_PHOTOGRAPHER_BY_USER} ${record.date}`
              }
            });
            dispatch({
              type: EventTypes.OPEN_MODAL,
              payload: {
                open: true,
                modal: "Email"
              }
            });
        }
      },
      {
        title:  locales[selectedLanguage].cancel_booking,
        callBack: () => {
            dispatch({ type: EventTypes.CANCEL_BOOKING,
              payload: 
                record
            });
            dispatch({ type: EventTypes.OPEN_MODAL,
              payload: { open: true,
                modal: "Cancel" } });
        }
      },
    ];
};

export const photographerOptions = (locales, selectedLanguage, dispatch, record, getUserEmailCB) => {
    return [
      {
        title:  locales[selectedLanguage].contact_customer,
        callBack:  async () => {
            const email = await getUserEmailCB();
            dispatch({
              type: EventTypes.EMAIL_TO_SEND,
              payload: {
                email,
                emailSubject: `${EMAIL_SUBJECTS.CONTACT_USER_BY_PH} ${record.date}`,
              }
            });
            dispatch({ type: EventTypes.OPEN_MODAL,
              payload: { open: true,
                modal: "Email" } });
        }
      },
      {
        title:  locales[selectedLanguage].cancel_booking,
        callBack: () => {
            dispatch({ type: EventTypes.CANCEL_BOOKING,
              payload: 
                record
            });
            dispatch({ type: EventTypes.OPEN_MODAL,
              payload: { open: true,
                modal: "Remove" } });
        }
      },
    ];
};

export const albumOptions = (locales, selectedLanguage, dispatch, record, confirmDeletion) => {
    return ([
      {
        title:  locales[selectedLanguage].edit,
        callBack:  () => {
            dispatch({ type: EventTypes.EDIT_ALBUM,
              payload: record });
            dispatch({ type: EventTypes.OPEN_MODAL,
              payload: { open: true,
                modal: "Edit Album" } });
        }
      },
      {
        title:  locales[selectedLanguage].delete_album,
        callBack: 
        async () => {
            await confirmDeletion(record);
        }
      },
    ]
    );
};

export const FB_ERRORS = {
  OBJECT_NOT_FOUND: "storage/object-not-found",
  USER_NOT_FOUND: "auth/user-not-found",
  NETWORK_REQUEST_FAILED: "auth/network-request-failed",
  ACCOUNT_WITH_DIFF_CRED: "auth/account-exists-with-different-credential",
  WRONG_PASS: "auth/wrong-password",
  TOO_MANY_REQS: "auth/too-many-requests",
  EMAIL_IN_USE: "auth/email-already-in-use"
};

export const EMAIL_SUBJECTS = {
  CONTACT_PHOTOGRAPHER_BY_USER: "User's letter. Photosession on",
  CONTACT_USER_BY_PH: "Photographer's letter. Photosession on",
};

export const photographerLinks = (locales, selectedLanguage) => {
    return [
      {
        title: locales[selectedLanguage].Personal_Info,
        subtitle: locales[selectedLanguage].Provide_personal_details,
        image: fileText,
        link: "/account/personal-info",
        alt: "Personal information"
      },
      {
        title: locales[selectedLanguage].photographer_sessions,
        subtitle: locales[selectedLanguage].Control_your_photo_sessions,
        image: camera,
        link: "/account/sessions",
        alt: "Your sessions or reservations"
      },
      {
        title: locales[selectedLanguage].Preferences,
        subtitle: locales[selectedLanguage].Set_your_default_language_and_other,
        image: settings,
        link: "/account/preferences",
        alt: "Preferences"
      },
      {
        title: locales[selectedLanguage].Payment_Details,
        subtitle: locales[selectedLanguage].Review_payments_and_payouts,
        image: creditCard,
        link: "/account/payment-details",
        alt: "Payment Details"

      },
      {
        title: locales[selectedLanguage].Notifications,
        subtitle: locales[selectedLanguage].Choose_notifications_preferences,
        image: volumeDown,
        link: "/account/notifications",
        alt: "Notifications"
      },
      {
        title: locales[selectedLanguage].account_myalbums,
        subtitle: locales[selectedLanguage].account_myalbums_subtitle,
        image: myAlbums,
        link: "/account/my-albums",
        alt: "My albums"
      },
      {
        title: locales[selectedLanguage].account_switch_user,
        subtitle: null,
        image: userAccount,
        link: "",
        alt: "Switch to the user account"
      }

    ];
};

export const genres = [
  {
    name: "wedding",
    genre_id: 1
  },
  {
    name: "portrait",
    genre_id: 2
  },
  {
    name: "child",
    genre_id: 3
  },
  {
    name: "pregnancy",
    genre_id: 4
  },
  {
    name: "family",
    genre_id: 5
  },
  {
    name: "event",
    genre_id: 6
  },
  {
    name: "romantic",
    genre_id: 7
  },
  {
    name: "animal",
    genre_id: 8
  },
  {
    name: "business",
    genre_id: 9
  },

];

export const genresFB = [
  {
    genre_id: 1,
    name: "wedding",
    en: "Wedding",
    uk:"Шлюб",
    pl:"Ślub"
  },
  {
    genre_id: 2,
    name: "portrait",
    en: "Portrait",
    uk:"Портрет",
    pl:"Portret"
  },
  {
    name: "child",
    genre_id: 3,
    en: "Child",
    uk:"Дитяча",
    pl:"Dziecięca"
  },
  {
    name: "pregnancy",
    genre_id: 4,
    en: "Pregnancy",
    uk:"Вагітність",
    pl:"Ciąża"
  },
  {
    name: "family",
    genre_id: 5,
    en: "Family",
    uk:"Родинна",
    pl:"Rodzina"
  },
  {
    name: "event",
    genre_id: 6,
    en: "Event",
    uk:"Подія",
    pl:"Impreza"
  },
  {
    name: "romantic",
    genre_id: 7,
    en: "Romantic",
    uk:"Романтична",
    pl:"Romantyczny"
  },
  {
    name: "animal",
    genre_id: 8,
    en: "Animal",
    uk:"Тварини",
    pl:"Zwierzę"
  },
  {
    name: "business",
    genre_id: 9,
    en: "Business",
    uk:"Комерційна",
    pl:"Komercyjny"
  },
];

export const keywords = [
  [
    "Fotograf Warszawa",
    "Fotograf Gdansk",
    "Fotograf Wroclaw",
    "Fotograf Katowice",
    "Fotograf Krakow",
    "Fotograf Gdynia",
    "Fotograf Sopot",
    "Fotograf Trojmiasto",
  ],
  [
    "Fotograf ślubny",
    "Fotograf na wesele",
    "Fotograf noworodkowy",
    "Fotografia portretowa",
    "Fotografia biznesowa",
    "Studio fotograficzne",
    "Sesja komunijna",
    "Zdjęcia fotograficzne",
  ],
  [
    "Business fotograf",
    "Studio fotograficzne",
    "Fotografia biznesowa",
    "Fotografia reklamowa",
    "Zdjęcia fotograficzne",
    "Fotografia ciążowa",
    "Fotograf imprezy",
    "Fotograf eventowy",
    "Specjalne okazje",
  ]
];

export const reviewFields = [
  "communication",
  "experience",
  "pq_ratio",
  "satisfaction",
  "delivery_time"];