import React, { useContext, lazy } from "react";
import { store } from "../store/store";
import "../assets/styles/modals.scss";
import { useHistory } from "react-router-dom";
import EventTypes from "../store/eventTypes";

const LoginForm = lazy(() => import("../components/login"));
const SendEmail = lazy(() => import("./sendEmail"));
const CancelBooking = lazy(() => import("./cancelBooking"));
const RemoveBooking = lazy(() => import("./removeBookingFromCanceled"));
const EditAlbum = lazy(() => import("./editAlbum"));
const ConfirmDeletion = lazy(() => import("./confirmDeletion"));

const Modal = () => {
    const { state, dispatch } = useContext(store);
    const history = useHistory();

    history.listen(() => {
        dispatch({type: EventTypes.OPEN_MODAL,
          payload: {
            open: false
          }});
    });
    const renderModal = () => {
        switch (state.modal) {
            case "Login":
              return <LoginForm />;
            case "Email":
              return <SendEmail />;
            case "Cancel":
              return <CancelBooking />;
            case "Remove":
              return <RemoveBooking />;
            case "Edit Album":
              return <EditAlbum />;
            case "Confirm Deletion":
              return <ConfirmDeletion />;
            default:
              return null;
        }
    };

    return (
      <>
        {state.openModal ? (<div className='modal'> {renderModal()}</div>) : null}
      </>
    );
};
export default Modal;